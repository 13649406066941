import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Col, Collapse, Form, Input, Row, Space, Typography, FormInstance } from "antd"
import {
  Company,
  DynamicLayout,
  DynamicLayoutChart,
  DynamicLayoutDashboardKey,
  DynamicLayoutDashboardSectionKey
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { cloneMap, moveArrayItem } from "@moodys/cre-cpm.functions.array"
import DraggableTable, { DraggableTableRowProps } from "components/Admin/DragabbleTable"
import mapToDraggableTableRow from "components/Admin/DragabbleTable/functions"
import dynamicLayoutDefaults, { availableLayoutCharts } from "constants/dynamicLayout"
import { CompanyForm } from "models/company-form.model"
import {
  isCheckboxDisabled,
  configuration,
  getDefaultSelectedKeys,
  getInitialDataSource,
  getInitialSelectedRows,
  getKey,
  isCheckboxDisabledByDefault
} from "./functions"

const { Panel } = Collapse

const COMMA_CHAR = ","
export interface DynamicLayoutTabProps {
  company?: Company
  form: FormInstance<CompanyForm>
}

const DynamicLayoutTab: React.FC<DynamicLayoutTabProps> = ({ form, company }) => {
  const { t } = useTranslation("auth")
  const selectedLayouts = company?.attributes.layouts ?? (dynamicLayoutDefaults as DynamicLayout)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Map<string, React.Key[]>>(
    getInitialSelectedRows(selectedLayouts)
  )
  const [dataSource, setDataSource] = useState<Map<string, DraggableTableRowProps[]>>(
    getInitialDataSource(t, company?.attributes.layouts ?? availableLayoutCharts)
  )

  const onSelect =
    (dashboard: DynamicLayoutDashboardKey, section: DynamicLayoutDashboardSectionKey) =>
    (keys: React.Key[], data?: DraggableTableRowProps[]) => {
      const key = getKey(dashboard, section)
      const newSelectedRowKeys: Map<string, React.Key[]> = cloneMap(selectedRowKeys)
      const selectedCharts = data ?? dataSource?.get(getKey(dashboard, section))

      if (selectedCharts) {
        const orderedSelectedRowKeys = selectedCharts.reduce((accum, curr) => {
          if (keys && keys.includes(curr.key)) {
            return accum.concat([curr.key])
          }
          return accum
        }, [] as React.Key[])

        newSelectedRowKeys.set(key, orderedSelectedRowKeys)
      }

      setSelectedRowKeys(newSelectedRowKeys)
      const draggableTableRows =
        dataSource.get(key)?.map((row) => ({
          ...row,
          enabled: isCheckboxDisabled(row.key as DynamicLayoutChart, newSelectedRowKeys.get(key))
        })) ?? []

      dataSource.set(key, draggableTableRows)
    }

  const moveRow =
    (dashboard: DynamicLayoutDashboardKey, section: DynamicLayoutDashboardSectionKey) =>
    (dragIndex: number, hoverIndex: number) => {
      const newDataSource: Map<string, DraggableTableRowProps[]> = cloneMap(dataSource)
      const key = getKey(dashboard, section)
      const dataSourceForDashboardAndSection = newDataSource.get(key) ?? []
      const newData = moveArrayItem(dataSourceForDashboardAndSection, dragIndex, hoverIndex)
      newDataSource.set(key, newData)
      setDataSource(newDataSource)

      return newData
    }

  const onSort =
    (dashboard: DynamicLayoutDashboardKey, section: DynamicLayoutDashboardSectionKey) =>
    (dragIndex: number, hoverIndex: number) => {
      const newOrderedRow = moveRow(dashboard, section)(dragIndex, hoverIndex)
      onSelect(dashboard, section)(
        selectedRowKeys?.get(getKey(dashboard, section)) ?? [],
        newOrderedRow
      )
    }

  const restoreDefault =
    (dashboard: DynamicLayoutDashboardKey, section: DynamicLayoutDashboardSectionKey) => () => {
      const newDataSource: Map<string, DraggableTableRowProps[]> = cloneMap(dataSource)
      const key = getKey(dashboard, section)
      newDataSource.set(
        key,
        mapToDraggableTableRow(
          availableLayoutCharts[dashboard][section],
          (chart: DynamicLayoutChart) => ({
            key: chart,
            name: t(`companies.layout.charts.${chart}`),
            enabled: !isCheckboxDisabledByDefault(chart)
          })
        )
      )
      setDataSource(newDataSource)
      onSelect(dashboard, section)(
        dynamicLayoutDefaults[dashboard][section],
        mapToDraggableTableRow(
          dynamicLayoutDefaults[dashboard][section],
          (chart: DynamicLayoutChart) => ({
            key: chart,
            name: t(`companies.layout.charts.${chart}`),
            enabled: !isCheckboxDisabledByDefault(chart)
          })
        )
      )
    }

  const onClickDefaults =
    (dashboard: DynamicLayoutDashboardKey, section: DynamicLayoutDashboardSectionKey) => () => {
      restoreDefault(dashboard, section)()
    }

  const matchWithDefault = (
    dashboard: DynamicLayoutDashboardKey,
    section: DynamicLayoutDashboardSectionKey
  ) => {
    const key = getKey(dashboard, section)
    const defaultCharts = (getDefaultSelectedKeys(dashboard, section) ?? []) as string[]
    return (
      selectedRowKeys.get(key)?.join(COMMA_CHAR) === defaultCharts.join(COMMA_CHAR) &&
      selectedRowKeys.get(key)?.length === defaultCharts.length
    )
  }

  const createJSONFromState = () => {
    const plainState = {}
    const dashboards = Object.keys(dynamicLayoutDefaults) as DynamicLayoutDashboardKey[]
    dashboards.forEach((dashboard) => {
      ;(
        Object.keys(dynamicLayoutDefaults[dashboard]) as DynamicLayoutDashboardSectionKey[]
      ).forEach((section) => {
        const charts = selectedRowKeys?.get(getKey(dashboard, section))

        plainState[dashboard] = {
          ...plainState[dashboard],
          ...{
            [section]: charts ?? []
          }
        }
      })
    })

    return JSON.stringify(plainState)
  }

  useEffect(() => {
    form.setFieldsValue({
      layouts: createJSONFromState()
    })
    // eslint-disable-next-line
  }, [selectedRowKeys, dataSource])

  useEffect(() => {
    if (company?.attributes.layouts) {
      setSelectedRowKeys(getInitialSelectedRows(selectedLayouts))
    }
    // eslint-disable-next-line
  }, [company?.attributes.layouts])

  const getChartContainer = (
    dashboard: DynamicLayoutDashboardKey,
    section: DynamicLayoutDashboardSectionKey
  ) => (
    <Col span={24}>
      <Collapse data-testid={getTestId("dynamic_layout__collapse")}>
        <Panel
          header={t(`companies.layout.settings.${section}`).toUpperCase()}
          key={getKey(dashboard, section)}
          data-testid={getTestId("dynamic_layout__panel", getKey(dashboard, section))}
        >
          <Row>
            <Space direction="vertical" size="middle" className="rxd-full-width">
              <Row style={{ borderBottom: "1px solid #f0f0f0" }}>
                <Col span={23}>
                  <DraggableTable
                    showHeader={false}
                    dataSource={dataSource?.get(getKey(dashboard, section))}
                    columns={configuration}
                    listSelectedKeys={selectedRowKeys?.get(getKey(dashboard, section)) ?? []}
                    onItemSelect={onSelect(dashboard, section)}
                    pagination={false}
                    onSort={onSort(dashboard, section)}
                    testId={getTestId(
                      "dynamic_layout__draggable_table",
                      getKey(dashboard, section)
                    )}
                  />
                </Col>
              </Row>
              <Row justify="center">
                <Button
                  type="primary"
                  onClick={onClickDefaults(dashboard, section)}
                  disabled={matchWithDefault(dashboard, section)}
                  data-testid={getTestId(
                    "dynamic_layout__reset_defaults_btn",
                    getKey(dashboard, section)
                  )}
                >
                  {t("shared.reset_default")}
                </Button>
              </Row>
            </Space>
          </Row>
        </Panel>
      </Collapse>
    </Col>
  )

  return (
    <Row justify="center">
      <Form.Item name="layouts" initialValue={company?.attributes.layouts}>
        <Input type="hidden" />
      </Form.Item>
      <Col span={23}>
        <Space direction="vertical" size="large" className="rxd-full-width">
          <Row>
            <Col>
              <Typography.Title level={4}>{t("shared.dynamic_layout")}</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Space direction="vertical" size="large" className="rxd-full-width">
                <h3>
                  <strong>{t("companies.layout.settings.portfolio_dashboard")}</strong>
                </h3>
                <div>
                  {getChartContainer("portfolio_dashboard", "commercial")}
                  {getChartContainer("portfolio_dashboard", "multifamily")}
                </div>
                <strong>{t("companies.layout.settings.tenants")}</strong>
                <div>
                  {getChartContainer("tenants", "tenants_list")}
                  {getChartContainer("tenants", "tenants_dashboard")}
                </div>
              </Space>
            </Col>
            <Col span={11} offset={1}>
              <Space direction="vertical" size="large" className="rxd-full-width">
                <h3>
                  <strong>{t("companies.layout.settings.asset_dashboard")}</strong>
                </h3>
                <div>
                  {getChartContainer("asset_dashboard", "commercial")}
                  {getChartContainer("asset_dashboard", "multifamily")}
                </div>
              </Space>
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  )
}

export default React.memo(DynamicLayoutTab)
